import API from "@/services/api";
import {
  IAlert,
  IGetTrimestersResponse,
  IGetAlertsResponse,
  ISetAlertsResponse,
  IDeleteAlertsResponse
} from "../types/omip";

export enum EndpointVersion {
  V1 = "v1"
}

export enum EndpointName {
  Trimesters = '/omip/trimesters/',
  Alerts = '/omip/alerts/',
  DeleteAlert = '/omip/delete/alerts/',
}

const fetchTrimesters = () => {
  return API.get<IGetTrimestersResponse>({
    endpoint: EndpointName.Trimesters,
  });
}

const fetchAlerts = () => {
  return API.get<IGetAlertsResponse>({
    endpoint: EndpointName.Alerts,
  });
}

const addAlert = ({
  id,
  trimester,
  type,
  price
}: IAlert) => {
  return API.post<ISetAlertsResponse, IAlert>({
    endpoint: EndpointName.Alerts,
    data: {
      id,
      trimester,
      type,
      price
    }
  })
}

const deleteAlert = (id: number) => {
  return API.post<IDeleteAlertsResponse, { id: number }>({
    endpoint: EndpointName.DeleteAlert,
    data: {id}
  })
}

export default {
  fetchTrimesters,
  fetchAlerts,
  addAlert,
  deleteAlert
};