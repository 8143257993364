import { useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import { Alert, Autocomplete, Box, Button, Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import useOmip from "./hooks/useOmip";
import { useTranslation } from "react-i18next";
import { IAlert } from "./types/omip";
import { LoadingAnimation } from "../LoadingAnimation";
import { SmartTable } from "@gisce/oficina-virtual-components";

const Omip = () => {
  const { t } = useTranslation();
  const { error, trimesters, alerts, addAlert, deleteAlert } = useOmip();

  const [trimesterValue, setTrimesterValue] = useState("");
  const [typeValue, setTypeValue] = useState("");
  const [priceValue, setPriceValue] = useState(-1);
  const [value, setValue] = useState(false);
  
  const handleChangeTrimester = (newTrimester: string | null) => {
    setTrimesterValue(newTrimester !== null ? newTrimester : "");
    setValue(newTrimester !== null && typeValue !== "" && priceValue >= 0);
  }

  const handleChangeType = (value: string) => {
    setTypeValue(value);
    setValue(trimesterValue !== "" && value !== "" && priceValue >= 0);
  }

  const handleChangePrice = (value: number) => {
    setPriceValue((value >= 0 && !Number.isNaN(value)) ? value : -1);
    setValue(
      trimesterValue !== "" &&
      typeValue !== "" &&
      (value >= 0 &&!Number.isNaN(value))
    );
  }

  const handleAddAlert = () => {
    addAlert({
      id: null,
      trimester: trimesterValue,
      type: typeValue,
      price: priceValue
    })
  }

  const JSON_to_arrays = () => {
    const columns = [
      {
        title: t('common:text.omip_trimester'),
        key: "trimester",
      },
      {
        title: t('common:text.omip_type'),
        key: "type",
      },
      {
        title: t('common:text.omip_price'),
        key: "price",
      },
      {
        title: t('common:text.omip_actions'),
        key: "actions",
      },
    ]

    const alertsSource = Array.isArray(alerts) ? alerts.map((alert: IAlert, _index: number) => {
      let alertType = ""

      switch(alert.type) {
        case "<":
          alertType = t('common:text.omip_type_lower');
          break;
        case '>':
          alertType = t('common:text.omip_type_greater');
          break;
        case '==':
          alertType = t('common:text.omip_type_equal');
          break;
        default:
          break;
      }

      const deleteButton = (
        <Button
          key={"deleteAlert"}
          variant={'contained'}
          color={'error'}
          title={t("common:text.omip_delete")}
          onClick={() => deleteAlert(alert.id)}
          style={{ marginTop: '5px', marginLeft: '5px', marginRight: '5px', overflow: 'hidden' }}
          startIcon={<Delete />}
        >
          {t('common:text.omip_delete')}
        </Button>
      );

      const values = {
        id: alert.id,
        trimester: alert.trimester,
        type: alertType,
        price: alert.price,
        actions: deleteButton
      };

      return values;
    }) : [];

    return {
      columns,
      alertsSource
    };
  }

  const adaptedData = JSON_to_arrays();

  return (
    <>
      <Box mb={2}>
        <Typography variant={"h6"}>
          {t('common:text.omip_alert_title')}
        </Typography>
      </Box>

      { error &&
        <Alert severity="error" sx={{mb: 2}}>
          {t(`common:text.omip_${error.type}`)}
        </Alert>
      }

      <Grid
        container
        direction="row"
        justifyContent="center"
        spacing={1}
        mb={2}
      >
        <Grid item xs={12} md={4}>
          <Autocomplete
            id="trimester-selector"
            options={trimesters as string[]}
            renderInput={
              (params) => <TextField
                {...params}
                label={t("common:text.omip_trimester")}
                variant="outlined"
                size="small"
              />
            }
            onChange={
              (_event, newTrimester) => handleChangeTrimester(newTrimester)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Select
            size="small"
            value={typeValue}
            fullWidth
            onChange={(event) => handleChangeType(event.target.value)}
          >
            <MenuItem value={'<'}>{t('common:text.omip_type_lower')}</MenuItem>
            <MenuItem value={'>'}>{t('common:text.omip_type_greater')}</MenuItem>
            <MenuItem value={'=='}>{t('common:text.omip_type_equal')}</MenuItem>
          </Select>
        </Grid>
        <Grid item container xs={12} md={3}>
          <TextField
            size="small"
            variant="outlined"
            label={t("common:text.omip_price")}
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
            onChange={
              (event) => handleChangePrice(parseFloat(event.target.value))
            }
            />
        </Grid>
        <Grid item container xs={12} md={2}>
          <Button
            color="primary" 
            variant="contained" 
            fullWidth 
            style={{borderRadius: "5px", height: "fit-content"}} 
            startIcon={<Add />} 
            disabled={!value}
            onClick={() => handleAddAlert()}
          >
            {t("common:text.omip_add_alert")}
          </Button>
        </Grid>
      </Grid>

      {adaptedData ?
        <SmartTable
          columns={ adaptedData?.columns ?? []} 
          dataSource={ adaptedData?.alertsSource ??[] }
          loading={!adaptedData}
          onRowStatus={undefined}
          loadingComponent={<LoadingAnimation />}
        />
      :
        <LoadingAnimation/>
      }
    </>
  )
}

export default Omip;