import { RootState } from "@/store";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { IAlert, IError } from "../types/omip";

import {
  FETCH_TRIMESTERS,
  FETCH_ALERTS,
  ADD_ALERT,
  DELETE_ALERT
} from "@/constants";

import { default as omipService } from "../services/omip";

const useOmip = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState<IError|null>(null);

  const trimesters = useSelector((state: RootState) => state?.omip?.trimesters);
  const alerts = useSelector((state: RootState) => state?.omip?.alerts);

  useEffect(() => {
    if (!trimesters.length) {
      omipService.fetchTrimesters().then(response => {
        dispatch({
          type: FETCH_TRIMESTERS,
          payload: response.promise.data
        });
        setError(null);
      }).catch(_error => {setError({type: "trimesterFetchError"})});
    };

    if (!alerts.length) {
      omipService.fetchAlerts().then(response => {
        dispatch({
          type: FETCH_ALERTS,
          payload: response.promise.data
        });
        setError(null);
      }).catch(_error => {setError({type: "alertFetchError"})});
    }
  }, [])

  const addAlert = (alert: IAlert) => {
    omipService.addAlert(alert).then(response => {
      if (response.promise.data.status == 200) {
        alert.id = response.promise.data.id;
        dispatch({
          type: ADD_ALERT,
          payload: alert,
        });
        setError(null);
      }
    }).catch(_error => {setError({type: "alertAddError"})});
  }

  const deleteAlert = (id: number | null) => {
    if (id !== null) {
      omipService.deleteAlert(id).then(response => {
        if (response.promise.data.status == 200) {
          dispatch({
            type: DELETE_ALERT,
            payload: id,
          });
          setError(null);
        }
      }).catch(_error => {setError({type: "alertDeleteError"})});
    }
    else {
      setError({type: "alertDeleteError"})
    }
  }

  return {
    trimesters,
    alerts,
    error,
    addAlert,
    deleteAlert
  }
}

export default useOmip;