import { FC, useState } from "react";
import { useEffect } from "react";
import { IServerElement } from "./types";
import { RichHTML } from "@gisce/oficina-virtual-components";


interface IServerElementProps {
  htmlElement: IServerElement;
}
/**
 * Renders the provided HTML element as props.
 * Sets the width and height of the rendered element.
 * ⚠ Warning: Utilizes `dangerouslySetInnerHTML` to add the element.
 * 
**/
const ServerElement: FC<IServerElementProps> = ({htmlElement}) => {
  let { blockHeight, blockWidth, htmlBlock } = htmlElement;
  const [ innerHTML, setInnerHTML ] = useState<string>("");

  
  if (!blockWidth)
    blockWidth = "100%";
  if (!blockHeight)
    blockHeight = "100%";

  useEffect(() => {
    setInnerHTML(htmlBlock);
  }, [htmlBlock])


  return (
    <div
      style={{width: blockWidth, height: blockHeight, overflow: "auto", margin: "0px auto"}}
    >
        <RichHTML html={innerHTML} />
    </div>
  )
}

export default ServerElement;
