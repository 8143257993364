import { createReducer } from "@/utils/misc";
import {
  FETCH_TRIMESTERS,
  FETCH_ALERTS,
  ADD_ALERT,
  DELETE_ALERT
} from '@/constants';

const initialState = {
  trimesters: [],
  alerts: [],
};

export default createReducer(initialState, {
  [FETCH_TRIMESTERS]: (state, payload) => {
    return {
      ...state,
      trimesters: payload,
    };
  },

  [FETCH_ALERTS]: (state, payload) => {
    return {
      ...state,
      alerts: payload,
    };
  },

  [ADD_ALERT]: (state, payload) => {
    return {
      ...state,
      alerts: [...state.alerts, payload]
    }
  },

  [DELETE_ALERT]: (state, payload) => {
    return {
      ...state,
      alerts: state.alerts.filter(alert => alert.id !== payload)
    }
  }
});
